// Input
import React from 'react';
import styles from './Input.module.sass';
import cn from 'classnames';

export interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  error?: string;
  icon?: React.ReactNode;
  label?: string;
  sublabel?: string;
}

const Input: React.FC<InputProps> = ({
  error,
  icon,
  label,
  sublabel,
  ...inputProps
}) => {
  return (
    <div className={styles.wrapper}>
      {label && (
        <label
          className={cn(styles.label, { [styles.labelNoMargin]: sublabel })}
          htmlFor={inputProps.id}
        >
          {label}
        </label>
      )}
      {sublabel && <p className={styles.sublabel}>{sublabel}</p>}
      <span className={styles.inputWrapper}>
        {icon}
        <input
          {...inputProps}
          className={cn(styles.input, {
            [styles.inputError]: !!error,
            [styles.inputWithIcon]: !!icon,
          })}
        />
      </span>
      {!!error && <p className={styles.errorText}>{error}</p>}
    </div>
  );
};

export default Input;
