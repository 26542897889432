// AuthForm
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import styles from './AuthForm.module.sass';
import Input from 'components/common/Input';
import { getLocalImageUrl } from 'utils/image';
import { GoogleSSOauth } from '../GoogleSSOauth';
import { TermsAndPolicy } from '../TermsAndPolicy';
import { AuthFormState, AuthFormType, LinkAuthFormType } from 'types/auth';
import { passwordResetEmail } from 'services/firebaseAuth';
import { validateEmail, validatePasswordOrReturnError } from 'utils/validators';
import { useAuth } from 'hooks/useAuth';
import Loader from 'components/common/Loader';

export interface AuthFormProps {
  authForm: AuthFormState;
  authFormType: AuthFormType;
  setAuthForm: React.Dispatch<React.SetStateAction<AuthFormState>>;
  setAuthFormType:
    | React.Dispatch<React.SetStateAction<AuthFormType>>
    | React.Dispatch<React.SetStateAction<LinkAuthFormType>>;
  onSignUp: () => void;
  onLogin: () => void;
  onGoogleSSO: () => void;
}

const AuthForm: React.FC<AuthFormProps> = ({
  authForm: { email, password },
  authFormType,
  setAuthForm,
  setAuthFormType,
  onSignUp,
  onLogin,
  onGoogleSSO,
}) => {
  const {
    authState: { status: authStatus, error: authError },
  } = useAuth();

  const [emailError, setEmailError] = useState<string | undefined>();
  const [passwordError, setPasswordError] = useState<string | undefined>();
  const [emailSendNote, setEmailSendNote] = useState<string | void>('');

  useEffect(() => {
    if (authError) {
      const emailErrorRegex = /(user)|(email)/gi;

      if (emailErrorRegex.test(authError)) setEmailError(authError);
      else setPasswordError(authError);
    }
  }, [authError]);

  const handleEmailAuth = async () => {
    if (authFormType === 'forgot-password') {
      // NOTE: Is this the right password reset for soona?
      setEmailSendNote(await passwordResetEmail(email));
    } else if (authFormType === 'sign-up') handleSignUp();
    else handleLogin();
  };

  const onInputChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { id, value },
  }) => {
    setAuthForm((form) => ({ ...form, [id]: value }));

    if (id === 'email') setEmailError(undefined);
    else if (id === 'password') setPasswordError(undefined);
  };

  const toggleFormType = () => {
    setAuthFormType((type: AuthFormType | LinkAuthFormType) =>
      type === 'sign-up' ? 'login' : 'sign-up'
    );
  };

  const handleLogin = () => {
    if (checkErrors()) onLogin();
  };

  const handleSignUp = () => {
    if (checkErrors()) onSignUp();
  };

  const handleGoogleSSO = () => onGoogleSSO();
  const handleForgotPassword = () => setAuthFormType('forgot-password');

  const checkErrors = () => {
    const isValidEmail = validateEmail(email);
    setEmailError(isValidEmail ? undefined : 'Invalid email');

    const isValidPasswordOrError = validatePasswordOrReturnError(password);

    setPasswordError(
      typeof isValidPasswordOrError === 'string'
        ? isValidPasswordOrError
        : undefined
    );

    return !!isValidEmail && !!isValidPasswordOrError;
  };

  const handleEmailKeyUp: React.KeyboardEventHandler<HTMLInputElement> = ({
    key,
  }) => {
    if (key === 'Enter' || key === 'Tab') {
      document.getElementById('password')?.focus();
    }
  };

  const handlePasswordKeyUp: React.KeyboardEventHandler<HTMLInputElement> = ({
    key,
  }) => {
    if (key === 'Enter') {
      if (authFormType === 'sign-up') handleSignUp();
      else handleLogin();
    }
  };

  return (
    <div className={styles.authForm}>
      <div className={styles.titleLogo}>
        <img
          src={getLocalImageUrl('/images/trend-logo-black.svg')}
          alt="trend-logo"
        />
      </div>
      <h3>
        {emailSendNote ? emailSendNote : AUTH_FORM_COPY[authFormType].title}
      </h3>
      <h4>{AUTH_FORM_COPY[authFormType].titleLineTwo}</h4>
      <p className={styles.subtitle}>{AUTH_FORM_COPY[authFormType].subtitle}</p>
      {authStatus === 'loading' || authStatus === 'authenticated' ? (
        <div className={styles.loadingContainer}>
          <Loader />
        </div>
      ) : (
        <>
          <GoogleSSOauth
            isGoogleAuth={authFormType !== 'forgot-password'}
            handleGoogleSSO={handleGoogleSSO}
            styles={styles}
          />
          <Input
            id="email"
            error={emailError}
            placeholder={
              authFormType === 'login' ? 'Login Email' : 'Company Email'
            }
            type="email"
            value={email}
            onChange={onInputChange}
            onKeyUp={handleEmailKeyUp}
          />
          {authFormType !== 'forgot-password' && (
            <Input
              id="password"
              error={passwordError}
              placeholder="Password"
              type="password"
              value={password}
              onChange={onInputChange}
              onKeyUp={handlePasswordKeyUp}
            />
          )}
          {authFormType === 'login' && (
            <button
              onClick={handleForgotPassword}
              className={styles.forgotPassword}
            >
              Forgot Password?
            </button>
          )}
          <button className="button-3d" onClick={handleEmailAuth}>
            {AUTH_FORM_COPY[authFormType].actionButtonText}
          </button>
        </>
      )}
      <div className={styles.footerContent}>
        {authFormType === 'sign-up' && <TermsAndPolicy />}
        <p className={styles.toggleText}>
          {AUTH_FORM_COPY[authFormType].toggleText}{' '}
          <a onClick={toggleFormType}>
            {AUTH_FORM_COPY[authFormType].toggleButtonText}
          </a>
        </p>
      </div>
    </div>
  );
};

export default AuthForm;

export type AuthFormCopy = Record<
  | 'title'
  | 'titleLineTwo'
  | 'subtitle'
  | 'actionButtonText'
  | 'toggleText'
  | 'toggleButtonText',
  string
>;

export const AUTH_FORM_COPY: Record<AuthFormType, AuthFormCopy> = {
  login: {
    title: 'Welcome back!',
    titleLineTwo: 'Log in as a brand',
    subtitle: 'Pair up with creators across the country',
    actionButtonText: 'Login',
    toggleText: 'Don’t have an account?',
    toggleButtonText: 'Sign Up',
  },
  'sign-up': {
    title: 'Sign up as a brand',
    titleLineTwo: '',
    subtitle: 'Find the creators that fit your brand',
    actionButtonText: 'Sign up',
    toggleText: 'Already have an account?',
    toggleButtonText: 'Login',
  },
  'forgot-password': {
    title: 'Enter your email below to reset your password. Check your inbox.',
    titleLineTwo: '',
    subtitle: '',
    actionButtonText: 'Reset password',
    toggleText: '',
    toggleButtonText: '',
  },
};
